// ============================================================================
// BatchApi
// -------------------
// Job batches api
// ============================================================================

// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

import { RequiredArguments, NotImplementedYet } from '@/helpers/methods'

// -------
// Exports
// -------
export default {
    batches: {
        create: NotImplementedYet,
        read: function (batchId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
            RequiredArguments({ batchId: batchId })
            return Backend.GET(URLS.api.batches.uri, [batchId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
        },
        update: NotImplementedYet,
        delete: NotImplementedYet
    }
}
