<template>
	<w-layout fill-height>
		<MobileAppEditor v-if="selectedApplication" v-model="selectedApplication" :loading="loading" :processing="processing" />
		<MobileAppListDetails v-else v-model="applications" :processing="processing" />
		<MobileAppsProgress v-if="showProgress" :progress="progress" :indeterminate="unknownProgress" :message="progressMessage" />
	</w-layout>
</template>

<script>
import Vue from 'vue'
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'
import HandleSideBarMixin from '@/mixins/SideBar/HandleSideBarMixin'
import MobileAppList from '@/components/SuperAdmin/MobileApp/MobileAppList'
import BatchService from '@/services/Common/BatchService'

export default {
	name: 'SuperAdminMobileAppManager',
	components: {
		MobileAppEditor: () => ({
			component: import('@/components/SuperAdmin/MobileApp/MobileAppEditor')
		}),
		MobileAppListDetails: () => ({
			component: import('@/components/SuperAdmin/MobileApp/MobileAppListDetails')
		}),
		MobileAppsProgress: () => ({
			component: import('@/components/SuperAdmin/MobileApp/MobileAppsProgress')
		})
	},
	mixins: [SuperAdminModuleGuard, HandleSideBarMixin],
	data: function () {
		return {
			loading: false,
			fetching: false,
			syncing: false,
			upgrading: false,
			fetchprogress: 0,
			syncprogress: 0,
			syncbatchid: null,
			upgradebatchprogress: 0,
			upgradebatchid: null,
			blankApplication: null
		}
	},
	computed: {
		selectedApplication: {
			get: function () {
				return this.$store.state.mobileapps.selected
			},
			set: function (val) {
				this.$store.dispatch('mobileapps/setSelected', val)
			}
		},
		applications: {
			get: function () {
				return this.$store.state.mobileapps.list
			},
			set: function (list) {
				this.$store.dispatch('mobileapps/setList', list)
			}
		},
		accountingFirms: {
			get: function () {
				return this.$store.state.mobileapps.accountingFirms
			},
			set: function (list) {
				this.$store.dispatch('mobileapps/setAccountingFirms', list)
			}
		},
		fetchingMessage: function () {
			return this.$tc('mobileapp.list.fetching', this.fetchprogress, { progress: this.fetchprogress })
		},
		syncingMessage: function () {
			return this.$tc('mobileapp.list.syncing', this.syncprogress, { progress: this.syncprogress })
		},
		upgradingMessage: function () {
			return this.$tc('mobileapp.list.upgrading', this.syncprogress, { progress: this.syncprogress })
		},
		showProgress: function () {
			return this.fetching || this.syncing || this.upgrading
		},
		progress: function () {
			let result = ''
			if (this.upgrading) {
				result = this.upgradebatchprogress
			} else if (this.syncing) {
				result = this.syncprogress
			} else if (this.fetching) {
				result = this.fetchprogress
			}
			return result
		},
		unknownProgress: function () {
			return !this.upgrading && !this.syncing && this.fetching
		},
		progressMessage: function () {
			let result = ''
			if (this.upgrading) {
				result = this.upgradingMessage
			} else if (this.syncing) {
				result = this.syncingMessage
			} else if (this.fetching) {
				result = this.fetchingMessage
			}
			return result
		},
		processing: function () {
			return this.upgrading || this.syncing
		}
	},
	watch: {
		loading: {
			handler: function () {
				this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, this.loading)
			}
		},
		syncbatchid: {
			handler: function () {
				if (this.syncbatchid !== null) {
					this.startPollingSyncingProgress()
				}
			}
		},
		upgradebatchid: {
			handler: function () {
				if (this.upgradebatchid !== null) {
					this.startPollingUpgradeBatchProgress()
				}
			}
		}
	},
	created: function () {
		this.loading = true
		Promise.all([this.loadMobileAppList(), this.loadCurrentUpgrading(), this.loadCurrentSyncing(), this.init()]).finally(
			() => {
				this.loading = false
				this.loadAccountingFirmList();
			}
		)
	},
	destroyed: function () {
		this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
		this.$store.dispatch('mobileapps/reset')
		this.setHasSidebar(false)
		this.setPermanentSidebar(false)
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.SELECTED_MOBILE_APP, action: this.selectMobileApp },
				{ event: this.events.CLEAR_SELECTED_MOBILE_APP, action: this.clearSelectedMobileApp },
				{ event: this.events.CREATE_NEW_MOBILE_APP, action: this.createNewMobileApp },
				{ event: this.events.CREATE_MOBILE_APP, action: this.createMobileApp },
				{ event: this.events.UPDATE_MOBILE_APP, action: this.updateMobileApp },
				{ event: this.events.DELETE_MOBILE_APP, action: this.deleteMobileApp },
				{ event: this.events.RESET_MOBILE_APP_LIST, action: this.resetMobileAppList },
				{ event: this.events.LOAD_MOBILE_APP_LIST, action: this.loadMobileAppList },
				{ event: this.events.UPGRADE_MOBILE_APP, action: this.upgradeMobileApp },
				{ event: this.events.UPGRADE_MOBILE_APPS, action: this.upgradeMobileApps },
				{ event: this.events.UPGRADE_AND_UPDATE_MOBILE_APP, action: this.upgradeAndUpdateMobileApp }
			]
		},
		init: function () {
			const MobileAppListClass = Vue.extend(MobileAppList)
			const mobileAppListComponent = new MobileAppListClass({
				parent: this
			})
			this.appEventBus.emit(this.appEvents.SET_SIDEBAR_CONTENT, {
				canMinimize: this.$vuetify.breakpoint.mdAndUp,
				canClose: this.$vuetify.breakpoint.smAndDown,
				title: this.$t('mobileapp.name'),
				moduleInstance: mobileAppListComponent,
				emitter: this
			})
			this.setHasSidebar(true)
			this.setPermanentSidebar(true)
		},
		loadAccountingFirmList: function () {
			return this.service.getAccountingFirmList().then(list => this.$store.dispatch('mobileapps/setAccountingFirms', list))
		},
		loadMobileAppList: function () {
			this.fetching = true
			return this.service
				.listMobileApp()
				.then(list => (this.applications = list))
				.finally(() => {
					this.fetching = false
				})
		},
		loadCurrentSyncing: async function () {
			const mobileAppSync = await this.service.getMobileAppSync()
			if (mobileAppSync) {
				this.syncing = true
				this.syncbatchid = mobileAppSync.batch_id
			} else {
				this.syncing = false
				this.syncbatchid = null
			}
		},
		loadCurrentUpgrading: async function () {
			const mobileAppUpgradeBatch = await this.service.getMobileAppUpgradeBatch()
			if (mobileAppUpgradeBatch) {
				this.upgrading = true
				this.upgradebatchid = mobileAppUpgradeBatch.batch_id
			} else {
				this.upgrading = false
				this.upgradebatchid = null
			}
		},
		resetMobileAppList: function () {
			this.loading = true
			this.syncing = true
			this.service
				.createMobileAppSync()
				.then(mobileAppSync => {
					this.syncing = mobileAppSync ? true : false
					this.syncbatchid = mobileAppSync ? mobileAppSync.batch_id : null
				})
				.catch(() => {})
				.finally(() => {
					this.loading = false
				})
		},
		loadApplication: function (mobileAppId) {
			let result = Promise.resolve()
			if (mobileAppId !== null) {
				result = this.service.getMobileApp(mobileAppId)
			}
			return result
		},
		selectMobileApp: function (mobileAppId, force = false) {
			this.loading = true
			let result = Promise.resolve()
			const alreadyLoaded = this.applications.filter(application => application.id == mobileAppId)
			if (force || alreadyLoaded.length != 1) {
				result = this.loadApplication(mobileAppId).then(application => {
					this.selectedApplication = application
				})
			} else {
				this.selectedApplication = alreadyLoaded[0]
			}
			return result.finally(() => (this.loading = false))
		},
		resetBlankMobileApp: function () {
			this.blankApplication = {
				logo: null,
				logo_file_path: '',
				color: '',
				title: '',
				subtitle: '',
				promotional_text: '',
				description: this.$t('mobileapp.defaults.description'),
				keywords: this.$t('mobileapp.defaults.keywords'),
				accounting_firms: [],
				bundle_id: '',
				app_store_id: '',
				app_store_url: '',
				marketing_url: '',
				support_url: 'https://support.welyb.fr',
				play_store_url: ''
			}
		},
		clearSelectedMobileApp: function () {
			this.selectedApplication = null
		},
		createNewMobileApp: function () {
			if (!this.syncing) {
				this.resetBlankMobileApp()
				this.selectedApplication = this.blankApplication
			}
		},
		createMobileApp: function () {
			if (!this.syncing) {
				this.loading = true
				return this.service
					.createMobileApp(this.selectedApplication)
					.then(newApplication => this.loadMobileAppList().then(() => this.selectMobileApp(newApplication.id)))
					.finally(() => {
						this.eventBus.emit(this.events.MOBILE_APP_CREATED)
						this.loading = false
					})
			}
		},
		updateMobileApp: function () {
			if (!this.syncing) {
				this.loading = true
				return this.service
					.updateMobileApp(this.selectedApplication)
					.then(newApplication => this.loadMobileAppList().then(() => this.selectMobileApp(newApplication.id)))
					.finally(() => (this.loading = false))
			}
		},
		deleteMobileApp: function (mobileAppId) {
			if (!this.syncing) {
				this.loading = true
				return this.service
					.deleteMobileApp(mobileAppId)
					.then(() => this.loadMobileAppList())
					.finally(() => (this.loading = false))
			}
		},
		upgradeMobileApp: function (mobileAppId) {
			if (!this.syncing) {
				this.loading = true
				return this.service
					.upgradeMobileApp(mobileAppId)
					.then(this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('mobileapp.messages.upgrade_success')))
					.finally(() => (this.loading = false))
			}
		},
		upgradeMobileApps: function () {
			if (!this.syncing) {
				return this.service
					.createMobileAppUpgradeBatch()
					.then(mobileAppUpgradeBatch => {
						this.upgrading = mobileAppUpgradeBatch ? true : false
						this.upgradebatchid = mobileAppUpgradeBatch ? mobileAppUpgradeBatch.batch_id : null
					})
					.catch(() => {})
			}
		},
		upgradeAndUpdateMobileApp: function (mobileAppId) {
			if (!this.syncing) {
				this.loading = true
				return this.service
					.upgradeAndUpdateMobileApp(mobileAppId)
					.then(this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('mobileapp.messages.upgrade_and_update_success')))
					.finally(() => (this.loading = false))
			}
		},
		startPollingSyncingProgress: async function () {
			if (this.syncbatchid == null) {
				this.syncing = false
				this.syncprogress = 0
				this.loadMobileAppList()
			} else {
				const batch = await BatchService.getBatch(this.syncbatchid)
				if (batch === null || batch.progress >= 100) {
					this.syncing = false
					this.syncprogress = 0
					this.loadMobileAppList()
				} else {
					this.syncing = true
					this.syncprogress = batch.progress
					setTimeout(this.startPollingSyncingProgress, 1000)
				}
			}
		},
		startPollingUpgradeBatchProgress: async function () {
			if (this.upgradebatchid == null) {
				this.upgrading = false
				this.upgradebatchprogress = 0
				this.loadMobileAppList()
			} else {
				const batch = await BatchService.getBatch(this.upgradebatchid)
				if (batch === null || batch.progress >= 100) {
					this.upgrading = false
					this.upgradebatchprogress = 0
					this.loadMobileAppList()
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('mobileapp.messages.upgrade_all_success'))
				} else {
					this.upgrading = true
					this.upgradebatchprogress = batch.progress
					setTimeout(this.startPollingUpgradeBatchProgress, 1000)
				}
			}
		}
	}
}
</script>
