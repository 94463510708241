// ============================================================================
// BatchService
// -----------------------
// Job batches related services
// ============================================================================

// -------
// Imports
// -------
import { NotImplementedYet, RequiredArguments } from '@/helpers/methods'

import API from '@/apis/BatchApi'

// ---------
// Internals
// ---------
const Private = {
    requests: {
        batches: {
            create: NotImplementedYet,
            read: function (batchId) {
                RequiredArguments({ batchId: batchId })
                return API.batches.read(batchId)
            },
            update: NotImplementedYet,
            delete: NotImplementedYet
        }
    },
    service: {
        batches: {
            create: NotImplementedYet,
            read: function (batchId) {
                RequiredArguments({ batchId: batchId })
                return Private.requests.batches.read(batchId)
            },
            update: NotImplementedYet,
            delete: NotImplementedYet
        }
    }
}

// -------
// Exports
// -------
export default {
    createBatch: Private.service.batches.create,
    getBatch: Private.service.batches.read,
    updateBatch: Private.service.batches.update,
    deleteBatch: Private.service.batches.delete,

}
